import React from "react";
import { createAltText, WindowWidth } from "utils";
import { PageHeader, Section, Grid, Typography, Button, Box } from "../Layout";
import { styled } from "@mui/system";

import theme from "theme";

const crew = [
  {
    name: "Robert Hees & Haas",
    role: "Light & Sound",
    img: "Robbert.jpeg",
    description:
      "For Light & Sound we rely on the powerduo Hees & Haas. While Haas makes sure that we sound nice, Hees makes sure we look nice. We still don't know who has the hardest job of the two though...",
    link: "http://ufolightproductions.nl/",
    button: "Ufo light productions",
  },
  {
    name: "Marielle Grootobbink",
    role: "Photography",
    img: "Marielle.png",
    description:
      "Marielle has been our main photographer for the last couple of years. She alwasy shoots amazing pictures, making us look great on stage. We do suspect that she eats a lot of Köttbullar these days",
    link: "https://mariellegrootobbink.wixsite.com/mariellegrootobbink/",
    button: "Mariellegrootobbink",
  },
  {
    name: "Wilmy Schattevoet",
    role: "Band outfits",
    img: "Pagan_ways.png",
    description:
      "Wilmy is the most amazing tailor out there! She is the creative mind behind our band outfits, making sure we look very well on stage for the last 10 years! We are still in love with her designs!",
    link: "https://www.paganways.nl/",
    button: "Pagan ways",
  },
  {
    name: "Cato",
    role: "Socials",
    img: "Cato.png",
    description:
      "Cato used to go to middle school, but now she's a proper journalist! But luckily she finds time to make us pop up on one of your timelines.",
    link: "",
    button: "",
  },
];

const BandWrapper = styled("div")((props) => ({
  width: "100%",
  overflow: "hidden",
  position: "relative",
  padding: theme.spacing(2)
}));

const BandBackgroundWrapper = styled("div")((props) => ({
  position: "absolute",
  height: "100%",
  width: "100%",
  clip: "rect(0, auto, auto, 0)",
  top: 0,
  left: 0,
}));

const BandBackground = styled("div")((props) => ({
  position: "fixed",
  height: "100vh",
  width: "100%",
  backgroundSize: "100% auto",
  backgroundPosition: "bottom",
  backgroundRepeat: "no-repeat",
  backgroundImage: `url("/assets/img/group_transparent2.png")`,
  top: 0,
  left: 0,
  zIndex: -1,
  "&::after": {
    content: '""',
    position: "fixed",
    height: "100vh",
    width: "100%",
    top: 0,
    left: 0,
    display: "block",
    background: `rgb(0,0,0,.6)`,
  },
}));

const BandLogo = styled("div")(() => ({
  width: "100%",
  "& img": {
    width: "100%",
    maxWidth: "500px",
    margin: "auto",
  },
}));

export function CrewPanel({ img, children }) {
  return (
    <Grid container>
      <Grid item xs>
        <img
          src={`/assets/img/portraits/${img}`}
          alt={createAltText(img)}
          style={{
            width: "100%",
            margin: "auto",
            borderRadius: 3,
          }}
        />
      </Grid>
      <Grid item>{children}</Grid>
    </Grid>
  );
}

export default function BandPage() {
  let breakPoint = WindowWidth().breakPoint;

  return (
    <>
      <PageHeader noLogo />
      <Section noBackground noPadding>
        <BandWrapper>
          <BandBackgroundWrapper>
            <BandBackground />
          </BandBackgroundWrapper>

          <Grid container justifyContent="center">
            <Grid item textAlign="center" xs={12} mb={5} mt={5}>
              <BandLogo>
                <img src="/assets/img/logo/logo_main.svg" alt="the band" />
              </BandLogo>
            </Grid>
            <Grid
              item
              pb={4}
              xs={12}
              md={10}
              lg={8}
              sx={{ paddingLeft: 4, paddingRight: 4 }}
            >
              <Typography
                background="black"
                textAlign="center"
                mb={4}
                variant="body1"
              >
                <p>
                  A bit of punk, a pinch of good old rock music, a hint of metal
                  and whole lot of folk tunes is what The Royal Spuds call
                  “throwing a party in style”!
                </p>
                <p>
                  From their start in 2012, The Royal Spuds have been creating
                  their own style of music. This resulted in 2 full albums and 2
                  EP's. With the release of the debut-EP “Start Your Engines!”
                  the band introduced their recipe for “Spudrock”. In the
                  following years this EP was followed by the full studio
                  albums, “Wanted Drunk 'n' Alive” and “It's a Fecking
                  Freakshow”. At the end of 2018, The Royal Spuds released the
                  EP, “Unforgotten Lore”.
                </p>
                <p>
                  On April 2nd 2022 it's time for their latest release “Roots Of
                  Life”. With the same eclectic mix of styles, the same passion
                  and energy and fresh new songs to boot. Times may have been
                  tough but the party never stopped for The Royal Spuds!
                </p>
              </Typography>
            </Grid>
            <Grid item textAlign="center" pb={4} xs={12}>
              <Typography variant="body1" background="black">
                <p>Maarten van Vliet - Lead vocals & Banjo</p>
                <p>Michael Silver - Tin wistle(s) & backings vocals</p>
                <p>Milan Boom - Lead Guitar & backing vocals</p>
                <p>Robin Janssens - Rythm Guitar & backing vocals</p>
                <p>Dave Schrijvers - Bass & Artwork</p>
                <p>Mark de Jong - Drums</p>
              </Typography>
            </Grid>
          </Grid>
        </BandWrapper>
      </Section>
      <Section background="white" title="Crew and Vips">
        <Grid container spacing={3}>
          {breakPoint !== "sm" &&
            crew.map((member, index) => {
              return (
                <Grid item xs={12} lg={6} key={index}>
                  <Grid container spacing={2}>
                    <Grid item>
                      <img
                        src={`/assets/img/portraits/${member.img}`}
                        style={{
                          maxWidth:
                            breakPoint === "sm"
                              ? 120
                              : breakPoint === "md"
                              ? 130
                              : 260,
                        }}
                      />
                    </Grid>
                    <Grid item xs>
                      <Box sx={{ height: "100%" }}>
                        <Grid
                          container
                          direction="column"
                          justifyContent="space-between"
                          sx={{ height: "100%" }}
                        >
                          <Grid item mb={2}>
                            <Typography variant="body1">
                              {member.name}
                            </Typography>
                            <Typography variant="body2" color="green">
                              {member.role}
                            </Typography>
                          </Grid>
                          <Grid item xs>
                            <Typography variant="body2" gutterBottom>
                              {member.description}
                            </Typography>
                          </Grid>
                          <Grid item mb={1}>
                            {member.link && member.button && (
                              <Button href={member.link}>
                                {member.button}
                              </Button>
                            )}
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}

          {breakPoint === "sm" &&
            crew.map((member, index) => {
              return (
                <Grid item xs={12} lg={6} key={index}>
                  <Grid container spacing={2}>
                    <Grid item>
                      <img
                        src={`/assets/img/portraits/${member.img}`}
                        style={{
                          maxWidth: 120,
                        }}
                      />
                    </Grid>
                    <Grid item xs>
                      <Box sx={{ height: "100%" }}>
                        <Grid
                          container
                          direction="column"
                          justifyContent="space-between"
                          sx={{ height: "100%" }}
                        >
                          <Grid item mb={2}>
                            <Typography variant="body1">
                              {member.name}
                            </Typography>
                            <Typography variant="body2" color="green">
                              {member.role}
                            </Typography>
                          </Grid>
                          <Grid item mb={1}>
                            {member.link && member.button && (
                              <Button href={member.link}>
                                {member.button}
                              </Button>
                            )}
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2" gutterBottom>
                        {member.description}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
        </Grid>
      </Section>
    </>
  );
}
