import React from "react";

import MuiGrid from "@mui/material/Grid";

export default function Grid({
  textAlign = "left",
  pt = 0,
  pr = 0,
  pb = 0,
  pl = 0,
  ...props
}) {
  return (
    <MuiGrid
      sx={{
        textAlign: textAlign,
        paddingTop: pt,
        paddingRight: pr,
        paddingBottom: pb,
        paddingLeft: pl,
      }}
      {...props}
    />
  );
}
