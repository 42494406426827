import React from "react";
import MuiButton from "@mui/material/Button";
import { Link } from "react-router-dom";

export default function Button({
  children,
  variant = "contained",
  textvariant = "h6",
  href,
  to,
  startIcon,
  endIcon,
  mt,
  mb,
  ml,
  mr,
  ...props
}) {
  const buttonProps = {
    ...props,
    ...(href && {
      href,
      target: "_blank",
      rel: "noopener",
    }),
    ...(to && {
      to: to,
      component: Link,
    }),
  };

  return (
    <MuiButton
      sx={{
        ...(mt && { marginTop: mt }),
        ...(mr && { marginRight: mr }),
        ...(mb && { marginBottom: mb }),
        ...(ml && { marginLet: ml }),
        ...(props.weight === 'bold' && { fontWeight:600}),
      }}
      variant={variant}
      {...buttonProps}
      {...(startIcon && { startIcon: startIcon })}
      {...(endIcon && { startIcon: endIcon })}
      {...props}
    >
      {/* <Typography variant={textvariant} background={props.background}> */}
      {children}
      {/* </Typography> */}
    </MuiButton>
  );
}
