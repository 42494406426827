import React, { useState } from "react";

import SearchIcon from '@mui/icons-material/Search';
import { WindowWidth, formatDate } from "utils";

import { Button, Grid, Typography, TextField, InputAdornment, Select, MenuItem } from "components/Layout";
import theme from "theme";

import { useImportShows } from "components/Shows";

const today = new Date();

function ShowText({ text, background, type = "title", weight, ...props }) {
  return (
    <Typography
      type={type}
      variant={type === "subtitle" ? "body1" : "h6"}
      background={background}
      weight={weight}
      {...props}
    >
      {text}
    </Typography>
  );
}

function FilterHeader({search="", countries=[], selectedCountry='all', setSearch=()=>{}, setSelectedCountry = ()=>{}}){
  return (
    <Grid container mb={3} spacing={2} justifyContent="flex-end">
      <Grid item>
        <TextField 
          placeholder="search"
          variant="standard"
          value={search}
          sx={{
            '& .MuiInputBase-root:before': {
              borderBottom: `2px solid ${theme.palette.primary.main}`,
            },
            '& .MuiInputBase-root:hover:before': {
              borderBottom: `2px solid ${theme.palette.primary.main}`,
            },
            '& .MuiInputBase-root:after': {
              borderBottomColor: theme.palette.primary.main,
            },
          }}
          onChange={e=>{
            setSearch(e.target.value)
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            sx:{
              color:theme.palette.primary.main,
              fontWeight: 'bold',
              '& svg':{
                color:theme.palette.primary.main,
              },
              
            }
          }}
        />
      </Grid>
      <Grid item>

      <Select
        value={selectedCountry}
        label={'country'}
        variant="standard"
        onChange={e =>{
          setSelectedCountry(e.target.value);
        }}
        sx={{
          color: theme.palette.primary.main,
          fontWeight:'bold',
          '&.MuiInputBase-root::before': {
            borderBottom: `2px solid ${theme.palette.primary.main}`,
          },
          '&.MuiInputBase-root:hover:before': {
            borderBottom: `2px solid ${theme.palette.primary.main}`,
          },
          '&.MuiInputBase-root:after': {
            borderBottomColor: theme.palette.primary.main,
          }
        }}
      >
        <MenuItem 
          value={'all'}
          selected={selectedCountry === 'all'}
          onClick={e=>{
            if(selectedCountry.includes(e.target.value)){
              setSelectedCountry(...selectedCountry, e.target.value)
            }
          }}
        >
          ALL
        </MenuItem>
        {
          countries.map((country, index) =>{
            return (
              <MenuItem 
                key={index}
                value={country}
                selected={selectedCountry === country}
              >
                <img
                  width="30"
                  height="30"
                  style={{
                    verticalAlign: "middle",
                    marginRight: 10,
                    marginTop: -5,
                  }}
                  alt={`country_${country}`}
                  src={`/assets/img/countryflags/${country.toLowerCase()}.svg`}
                />
                {country.toUpperCase()}
              </MenuItem>
            ) 
          })
        }
      </Select>
      </Grid>
    </Grid>
  )
}

function ShowsRow({ 
  show, 
  background, 
  index,
  countries = [], 
  upcoming = false 
}) {
  let breakPoint = WindowWidth().breakPoint;

  return (
    <Grid
      item
      xs={12}
      key={index}
      sx={{
        borderBottom: `2px solid ${
          background === "white"
            ? theme.palette.primary.main
            : theme.palette.secondary.main
        }`,
        color: theme.palette.text.secondary,
      }}
    >
      <Grid container columnSpacing={2} alignItems="center" mb={2}>
        <Grid item sx={{ width: breakPoint === "sm" ? "100%" : 150 }}>
          <ShowText text={formatDate(show.date)} background={background}/>
        </Grid>

        <Grid
          item
          xs
          sx={{
            textAlign: "left",
          }}
        >
          {countries.includes(show.country.toLowerCase()) && (
            <img
              width="30"
              height="30"
              style={{
                verticalAlign: "middle",
                marginRight: 10,
                marginTop: -5,
              }}
              alt={`country_${show.country}`}
              src={`/assets/img/countryflags/${show.country.toLowerCase()}.svg`}
            />
          )}
          <ShowText text={show.name} background={background} component="span" weight={'bold'} />
          <ShowText
            text={show.location}
            type="subtitle"
            background={background}
          />
        </Grid>

        {upcoming && show.url && show.url.includes("https://") && (
          <Grid item>
            {
              <Button
                size="small"
                color="secondary"
                section="dark"
                href={show.url}
                weight={'bold'}
              >
                More info
              </Button>
            }
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default function ShowsList({
  upcoming,
  past,
  maxShowsInView,
  background,
}) {
  const shows = useImportShows().sort();
  const countries = ["ie", "de", "uk", "be", "nl"];

  var date = new Date();
  // remove 1 day, counters for 'today'
  date.setDate(date.getDate() - 1);

  const [search, setSearch] = useState("");
  const [selectedCountry, setSelectedCountry] = useState('all');

  const upcomingShows = shows
    .filter((show) => {
      const showDate = new Date(show.date);
      if (showDate.getTime() >= date.getTime()) {
        return show;
      }
    })
    .sort((showA, showB) => {
      const showDateA = new Date(showA.date);
      const showDateB = new Date(showB.date);
      return showDateA.getTime() > showDateB.getTime() ? -1 : 1;
    })
    .reverse();

  const pastShows = shows
    .filter((show) => {
      const showDate = new Date(show.date);
      if ((showDate.getTime() < date.getTime()) &&
          (selectedCountry === 'all' || show.country.toLowerCase() === selectedCountry.toLowerCase()) &&
          (search === "" || show.name.toLowerCase().includes(search.toLocaleLowerCase()) || show.location.toLowerCase().includes(search.toLocaleLowerCase()) || show.date.toLowerCase().includes(search.toLocaleLowerCase()))
      ) {
        return show;
      }
    })
    .sort((showA, showB) => {
      const showDateA = new Date(showA.date);
      const showDateB = new Date(showB.date);
      return showDateA.getTime() > showDateB.getTime() ? -1 : 1;
    })
    .filter((show, index) => index <= maxShowsInView);

  return (
    <>
      {past && 
        <FilterHeader 
          search={search}
          countries={countries}
          selectedCountry={selectedCountry} 
          setSearch={setSearch}
          setSelectedCountry={setSelectedCountry}
        />
      }
      <Grid container rowSpacing={2}>
        {upcoming && upcomingShows.length === 0 && (
          <Grid
            item
            xs={12}
            sx={{
              color: (theme) =>
                background === "white"
                  ? theme.palette.secondary.contrastText
                  : theme.palette.primary.contrastText,
              textAlign: "center",
            }}
            pb={4}
          >
            <Typography
              variant="body1"
              background={background}
              sx={{ fontStyle: "italic" }}
            >
              There are no upcoming shows at the moment.
            </Typography>
          </Grid>
        )}
        {upcoming &&
          upcomingShows.map((show, index) => {
            return (
              <ShowsRow
                show={show}
                countries={countries}
                background={background}
                index={index}
                key={index}
                upcoming
              />
            );
          })}
        {past &&
          pastShows.map((show, index) => {
            return (
              <ShowsRow
                show={show}
                countries={countries}
                background={background}
                index={index}
                key={index}
              />
            );
          })}
      </Grid>
    </>
  );
}
